import { OrganizationDetailsSessions } from '../components/Organization/Details/Sessions';

export function OrganizationSessionsRoute() {
  return <OrganizationDetailsSessions className='pl-8 pt-10 pr-12.5' />;
}

export const Component = OrganizationSessionsRoute;

export function clientLoader() {
  return null;
}
